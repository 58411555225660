import { useState } from 'react'
import { Container, Row, Col, Button, Form, ProgressBar } from 'react-bootstrap'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { db, storage } from './Firebase'
import { addDoc, collection , onSnapshot } from 'firebase/firestore'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'


const Admin = () => {
  const [ailmentButton, setailmentButton] = useState(true)
  const [allVideos, setAllVideos] = useState([])
  const [videoButton, setVideoButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progresspercent, setProgresspercent] = useState(0)
  const [imgUrl, setImgUrl] = useState()
  const [description , setDescription] = useState(0)
  const [allAilments, setAllAilments] = useState([])
  const [ailment, setailment] = useState({
    name: '',
    description: '',
    image: '',
  })


  const [htmlText,setHtmlText] = useState('');
  const [videotranscription,setVideotranscription] = useState('');

  
  
  const [video, setVideo] = useState({
    name: '',
    description: '',
    link: '',
  })
  const getVideos = async () => {
    await onSnapshot(collection(db, 'videos'), (snapshot) => {
      const temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id })
      })
      setAllVideos(temp)
    })
  }

  const getAilments = async () => {
    await onSnapshot(collection(db, 'ailments'), (snapshot) => {
      const temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id })
      })
      setAllAilments(temp)
    })
  }

  const loadDescription = (title) => {
    console.log(title)
    const desc =
      allVideos &&
      allVideos.filter((item) => {     

        return item.name===title
        setDescription(desc)
      })
    
    console.log(description)
  }
 
  // const saveailment = async (e) => {
  //  getAilments();
  //   await onSnapshot(collection(db, 'ailments'), (snapshot) => {
      
  //     const temp1 = []
  //     snapshot.forEach((doc) => {
  //       allAilments.push({ ...doc.data(), id: doc.id }).filter((item) => {
  //         return item.name[0] === e.target.value});
  //     })

  //     console.log("Temp1" + temp1);

  //    if(temp1==null)
  //    {
  //     addDoc(collection(db, 'ailments'), { ...ailment,description:htmlText })
  //     alert('ailment Saved')
  //     window.location.href="Admin";
  //    }
  //    else
  //    {
  //     setHtmlText(temp1.description)
  //    }
  //   })
  // }
  const saveailment = async (e) => {
    e.preventDefault()
    await addDoc(collection(db, 'ailments'), { ...ailment,description:htmlText })
    alert('ailment Saved')
    window.location.href="Admin";

  }
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
}

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]
  const handlechange = (content, delta, source, editor) => {
    console.log("entered")
    setHtmlText(content);
    
  }
  const handleFileUpload = async (e) => {
    const file = e.target.files[0]
    if (!file) return
    const storageRef = ref(storage, `ailments/${file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)
    try {
      setLoading(true)
      await uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          setProgresspercent(progress)
        },
        (error) => {
          alert(error)
          console.log(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL)
          })
        }
      )
    } catch (error) {
      console.log(error)
      alert(error)
    }
    setLoading(false)
  }

  const saveVideo = async (e) => {
    e.preventDefault()
    await addDoc(collection(db, 'videos'), { ...video,transcription:videotranscription })
    alert('Video Saved')
    window.location.href="Admin";

  }

  return (
    <div>
      <br />
      <div className='mobilegapcreator'>            
            
              <br />
              <br />
              <br />
            </div>
      <h1>Admin</h1>

      <Container>
        <Row>
          <Col md={6}>
            <Button
              variant='primary'
              onClick={() => {
                setailmentButton(true)
                setVideoButton(false)
              }}
            >
              Add ailment
            </Button>
          </Col>
          <div className='mobilegapcreator'>             
            <br />
            </div>
          <Col md={6}>
            <Button
              variant='primary'
              onClick={() => {
                setailmentButton(false)
                setVideoButton(true)
              }}
            >
              Add Video
            </Button>
          </Col>
        </Row>
      </Container>
      {ailmentButton && (
        <Container md={3}>
          <Form className='mt-5' onSubmit={saveailment}>
            <Form.Group>
              <Form.Label>ailment Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter ailment Name'
                value={ailment.name}
                onChange={(e) => setailment({ ...ailment, name: e.target.value })}
              />
            </Form.Group>
          
           
            <Form.Group>
              <Form.Label>ailment Description</Form.Label>

      <ReactQuill id="quill"
      value={htmlText}
        modules={modules}
        formats={formats}
        onChange={setHtmlText} />
   
             
            </Form.Group>
           <br />
            <Button variant='primary' type='submit'>
              Submit
            </Button>
          </Form>
        </Container>
      )}
      {videoButton && (
        <Container md={3}>
          <Form className='mt-5' onSubmit={saveVideo}>
            <Form.Group>
              <Form.Label>Video Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Video Name'
                onChange={(e) => {
                  setVideo({
                    ...video,
                    name: e.target.value})}}               
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Video Link</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Video Link'
                onChange={(e) => {
                  setVideo({
                    ...video,
                    link: e.target.value.replace('youtu.be/', 'www.youtube.com/embed/'),
                  })
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Video Transcription</Form.Label>
              {/* <Form.Control
                as='textarea'
                rows={3}
                placeholder='Enter Video Transcription'
                onChange={(e) =>
                  setVideo({ ...video, Transcription: e.target.value })
                }
              /> */}
                <ReactQuill id="quill"
      value={videotranscription}
        modules={modules}
        formats={formats}
        onChange={setVideotranscription} />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>Video Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                defaultValue= {description}
                onChange={(e) =>
                  setVideo({ ...video, description: e.target.value })
                }
              />
            </Form.Group> */}
            <Button variant='primary' type='submit'>
              Submit
            </Button>
          </Form>
        </Container>
      )}
    </div>
  )
}

export default Admin
