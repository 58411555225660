import { React, useState } from 'react'
import { NavDropdown, Nav, Navbar } from 'react-bootstrap'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'

// import '../config/FirebaseConfig'

const Header = () => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div>
      <Navbar
        className='nav-color'
        variant='light'
        fixed='top'
      >
        <Navbar.Brand>
          
            <a href='/'>
              <img src='../logo192.png' alt='' title='' style={{ width: "30%" ,  height:"30%;" }}/>
            </a>
          
        </Navbar.Brand>
          <div className='ms-auto'>
          <h2  style={{ color: "bisque"}}> Share Your Story</h2>
          <h5  style={{ color: "bisque"}}><i>Find a Story</i> </h5>


          </div>
          <Nav className="ms-auto">

            <Nav.Link
              href='/'
              style={{
                color: 'white',
                fontWeight: 'bold',
                paddingRight: '15px',
              }}
            >
              Home
            </Nav.Link>
            
            <Nav.Link
              href='/MainModule'
              style={{
                color: 'white',
                fontWeight: 'bold',
                paddingRight: '15px',
              }}
            >
              Blog
            </Nav.Link>
          
            
       
          </Nav>
        
       
      </Navbar>
      <div>
       
      </div>
      <hr style={{ height: '43.8pt', visibility: 'hidden' }} />
    </div>
  )
}

export default Header
