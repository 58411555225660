import { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { Button, Container, Form, Col, Navbar } from 'react-bootstrap'
import { collection, onSnapshot, addDoc } from 'firebase/firestore'
import { db, myAuth } from './Firebase'
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import ReCAPTCHA from 'react-google-recaptcha'
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';


const MainModule = () => {
  const [buttonText, setButtonText] = useState('Submit')
  const [commentstoadd, saveComments] = useState([])
  const [username, saveUsername] = useState([])
  const [showTranscription,setShowTranscription] = useState(false);
  const [comments, setComments] = useState([])
  const [ailmentsfordropdown, setAilmentsfordropdown] = useState([])
  const [allAilments, setAllAilments] = useState([])
  const [ailmentsToDisplay, setAilmentsToDisplay] = useState([])
  const [allVideos, setAllVideos] = useState([])
  const [videosToDisplay, setVideosToDisplay] = useState([])
  const [showDropDown, setShowDropDown] = useState(false)
  const [selectedVideosToShow, setSelectedVideosToShow] = useState([])
  const [videoNames, setVideoNames] = useState([])
  const [commentstoShow, setCommentstoShow] = useState([])
  const [buttonState, setButtonState] = useState(false)
  const [curr, setCurr] = useState([])
  const [transcription,setTranscription] = useState('')
  const [showMainModule, setShowMainModule] = useState(false)
  const [buttonEnabled, setButtonEnabled] = useState(false)

  useEffect(() => {
    getAilments()
    getVideos()
    getComments()
  }, [])

  const postsCollectionRef = collection(db, 'comments')
  let navigate = useNavigate()

  const showMainModulehere = (startingLetter) => {
    setShowMainModule(true)
    filterAilments(startingLetter)
  }
  

  const createPost = async () => {
    await addDoc(postsCollectionRef, {
      ailment: curr,
      content: commentstoadd,
      user: username,
    })
    filterVideosandailment(curr)
  }
  const getComments = async () => {
    await onSnapshot(collection(db, 'comments'), (snapshot) => {
      const temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id })
      })
      setComments(temp)
    })
  }

  const getAilments = async () => {
    await onSnapshot(collection(db, 'ailments'), (snapshot) => {
      const temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id })
      })
      setAllAilments(temp)
    })
  }

  const handleSelectedVideo = (transcription)=>{
 
      setTranscription(transcription)
      setShowTranscription(!showTranscription)
  }
  const getVideos = async () => {
    await onSnapshot(collection(db, 'videos'), (snapshot) => {
      const temp = []
      snapshot.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id })
      })
      setAllVideos(temp)
    })
  }

  const filterAilments = (startingLetter) => {
    console.log(startingLetter)
    setCurr('')
    const temp =
      allAilments &&
      allAilments.filter((item) => {
        return item.name[0] === startingLetter
      })
    console.log(temp)

    setAilmentsfordropdown(temp)
    setShowDropDown(true)
  }

  const filterVideos = (startingLetter) => {
    const temp =
      allVideos &&
      allVideos.filter((item) => {
        return item.name === startingLetter
      })
    const names = temp.map((item) => item.name)
    setVideoNames(['Select Name', ...new Set(names)])
    setVideosToDisplay(temp)
  }

  const filterVideosByTitle = (title) => {
    const temp1 =
      allVideos &&
      allVideos.filter((item) => {
        return item.name === title
      })
    console.log('HI')

    console.log(title)
    console.log(temp1)
    setVideosToDisplay(temp1)
  }
  const filterAilmentsbyTitle = (title) => {
    const temp =
      allAilments &&
      allAilments.filter((item) => {
        return item.name === title
      })

    setAilmentsToDisplay(temp)
  }
  const filterCommentsbyTitle = (title) => {
    const temp =
      comments &&
      comments.filter((item) => {
        return item.ailment === title
      })
    console.log(temp)
    setCommentstoShow(temp)
  }
  const filterVideosandailment = (title) => {
    console.log(curr)
    setCurr(title)
    filterVideosByTitle(title)
    filterAilmentsbyTitle(title)
    filterCommentsbyTitle(title)
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

  return (
    <>
      {' '}
     
      <div className='mobilegapcreator'>
              
            
              <br />
              <br />
              <br />
            </div>
      <div>
        <Col md={12}>
          <Container className='MainModule'>
            <div style={{ backgroundColor: 'bisque', color: '#2f0404' }}>
              {/* <button onClick={setShowMainModule(true)}> Home</button> */}

              {alphabet.map((item) => (
                <span
                  key={item}
                  className='alphabetmap title  align-center'
                  style={{ cursor: 'pointer'}}
                  onClick={(e) => {
                    filterAilments(item)
                  }}
                >
                  <strong>{item} </strong>
                </span>
              ))}
            </div>

            <Container>
              {showDropDown && (
                <Col md={2}>
                  <Form.Select
                    className='row d-flex justify-content-center'
                    aria-label='Default select example'
                    onChange={(e) => {
                      filterVideosandailment(e.target.value)
                    }}
                  >
                    <option
                      value={'No Option Selected'}
                      key={'No Option Selected'}
                    >
                      Select...
                    </option>

                    {ailmentsfordropdown && ailmentsfordropdown.length > 0 ? (
                      ailmentsfordropdown.map((item) => (
                        <option value={item.name} key={item.name}>
                          {item.name}
                        </option>
                      ))
                    ) : (
                      <option value=''>No ailments found</option>
                    )}
                  </Form.Select>
                </Col>
              )}
              <br />
              {curr.length > 0 && (
                <div className='homePage'>
                  {ailmentsToDisplay &&
                    ailmentsToDisplay.map((post) => {
                      return (
                        <div className='post'>
                          <div className='postHeader'>
                            <div className='title'>
                              <h1> {post.name}</h1>
                            </div>
                          </div>
                          <div className='postTextContainer'>
                            {' '}
                            {parse(post.description)}
                            {' '}
                          </div>
                        </div>
                      )
                    })}
                </div>
              )}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              {curr.length > 0 && (
                <Slider className='slider-pd30' {...settings}>
                  {videosToDisplay && videosToDisplay.length > 0 ? (
                    videosToDisplay.map((item) => (
                      <div
                        className='place-item layout-02 place-hover'
                        key={item.id}
                      >
                        <div className='place-inner'>
                          <div className='place-thumb hover-img'>
                            {/* <img src={item.image} alt='' /> */}
                            <iframe
                              width='720'
                              height='315'
                              src={item.link}
                              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                              allowFullScreen
                            ></iframe>
                             <button style={{ padding: '1px',
                      borderRadius: '30px',
                      margin: '1px',
                      width: '40%',
                      backgroundColor:'beige',
                      color:'brown',
                      border:0
                    }}
                      onClick={() => {
                        handleSelectedVideo(item.transcription)
                      }}

                    >
                      Show Transcription
                    </button>{' '} 
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h3></h3>
                  )}
                </Slider>
              )}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              {curr.length > 0 && (
                <section style={{ backgroundcolor: '#ad655f' }}>
                  <div className='container my-5 py-5 '>
                    <div className='row d-flex justify-content-center'>
                      <div className='col-md-12 col-lg-10'>
                        <div className='card text-dark'>
                          <div className='card-title'>
                            <h4 className='mb-0'>Recent comments</h4>
                            <p className='fw-light mb-4 pb-2 '>
                              Latest Comments section by users
                            </p>
                          </div>

                          <div className='anyclass'>
                            {commentstoShow.length > 0 &&
                              commentstoShow.map((post) => {
                                return (
                                  <div className='card-body p-4 '>
                                    <div className='d-flex flex-start '>
                                      <div>
                                        <h6 className='fw-bold mb-1'>
                                          {post.user}
                                        </h6>
                                        <div className='d-flex align-items-center mb-3'>
                                          <p className='mb-0'>{post.date}</p>
                                          <a href='#!' className='link-muted'>
                                            <i className='fas fa-pencil-alt ms-2'></i>
                                          </a>
                                          <a href='#!' className='link-muted'>
                                            <i className='fas fa-redo-alt ms-2'></i>
                                          </a>
                                          <a href='#!' className='link-muted'>
                                            <i className='fas fa-heart ms-2'></i>
                                          </a>
                                        </div>
                                        <p className='mb-0'>{post.content}</p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              {curr.length > 0 && (
                <section style={{ backgroundcolor: '#d94125' }}>
                  <div className='container my-5 py-5 text-dark'>
                    <div className='row d-flex justify-content-center'>
                      <div className='col-md-10 col-lg-8 col-xl-6'>
                        <div className='card'>
                          <div className='card-body p-4'>
                            <div className='d-flex flex-start w-100'>
                              <div className='w-100'>
                                <div className='form-outline'>
                                  <textarea
                                    className='form-control'
                                    id='textAreaExample'
                                    placeholder='Join the Conversation'
                                    rows='4'
                                    onChange={(event) => {
                                      saveComments(event.target.value)
                                    }}
                                  />
                                  <textarea
                                    className='form-control'
                                    placeholder='name'
                                    onChange={(event) => {
                                      saveUsername(event.target.value)
                                    }}
                                  />
                                </div>
                                <div className='justify-center'>
                                <ReCAPTCHA className='recap justify-center'
                                    sitekey={'6Lcqu5MoAAAAAAYqTQTIt4s4selKOoHW5f48sG9D'}
                                    onChange={() => setButtonEnabled(true)}
                                  />
                                 <br />          
                                 <br />    
              
            
                                  <button
                                    className='theme-btn'
                                    type='submit'
                                    name='submit-form'
                                    onClick={createPost}
                                    disabled={!buttonEnabled}
                                  >
                                    Submit
                                  </button>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

        <Modal className='modal'
        style={{
          zIndex:9999
        }}
          show={showTranscription}
          onHide={() => {
            setShowTranscription(false);
          }}
        >

        <Modal.Header>
        <Modal.Title><h1>Transcription</h1></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='postTextContainer'>
                            {' '}
                            {parse(transcription)}
                            {' '}
                          </div>
            </Modal.Body>
           </Modal>
            </Container>
          </Container>
        </Col>
      </div>
    </>
  )
}

export default MainModule
