const Footer = () => {
    return (
      <>
        {' '}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <footer className='main-footer style-three'>
          
          <div className='auto-container'>
          
            {/* <!-- Footer Bottom --> */}
            <div className='footer-bottom row'>
              <div className='copyright'>
                Copyright © 2023 ShareyourStory.
                
                {/* <br /> Powered by{' '}
                <span className='fa fa-cc-stripe'>{''}</span> */}
              </div>
              <div className='mobilespace'>
               Contact us to share your story at:<br/>
                sharemystory@gmail.com
                
                {/* <br /> Powered by{' '}
                <span className='fa fa-cc-stripe'>{''}</span> */}
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
  
  export default Footer
  