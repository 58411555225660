import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import MainModule from './MainModule'

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

const Home = () => {
  return (
    <>
      {' '}
     
      <div
        style={{
          backgroundColor: 'bisque',
          color: '#2f0404',
        }}
      >
        {' '}
        {/* <button onClick={setShowMainModule(true)}> Home</button> */}
        {/* {alphabet.map((item) => (
          <span
            key={item}
            className='title title-border-bottom align-center'
            style={{
              cursor: 'pointer',
            }}
            role='button'
            onClick={(e) => {
              window.location.href = 'MainModule'
            }}
          >
            {' '}
            <strong> {item}</strong>{' '}
          </span>
        ))} */}
      </div>{' '}
      <section className='news-section-two container mt-3 wrapper'>
        {' '}
        <div className='auto-container'>
          {' '}
          {/* <!-- Sec Title --> */}
          <div className='sec-title centered'>
            {' '}
            <div className='mobilegapcreator'>
              
            
              <br />
              <br />
              <br />
            </div>
            <div className='title title-border-bottom align-center' style={{ backgroundColor: 'bisque', color: '#2f0404' }}>Mission Statement</div>{' '}
            <div className='text'>
              {' '}
              <p>
                {' '}
               
                  <span>
                    The mission of this website is to raise awareness of certain
                    health conditions, share personal experiences, pointers on
                    how to overcome or cope up, highlight success stories and
                    empower people going through that condition. This website
                    does not have any medical professional or physician
                    references. It is not a substitute for medical professionals
                    recommendation as everyone's conditions are slightly
                    different and there is no one way the condition manifests in
                    humanity.
                  </span>{' '}
               
              </p>{' '}
              <br /> <br />{' '}
            </div>{' '}
            <div className='text'></div>{' '}
          </div>{' '}
          <div className='row clearfix'>
            {' '}
            {/* <!-- News Block Two --> */}
            <div className='news-block-two col-lg-6 col-md-4	col-sm-12'>
              {' '}
              <div
                className='inner-box wow fadeInLeft'
                data-wow-delay='0ms'
                data-wow-duration='1500ms'
              >
                       <table>
                 <tr  className='image'>
                  {' '}
                  <img
                    style={{
                      alignContent:'center',
                      padding:'10px',
                       width: '50%',
                       height: '250px',
                     }}
                    src='../images/Pavithra.jpg'
                  />
                  </tr>
                
               
                <tr className='lower-content row'>
                  {' '}
                  <h5> Pavithra </h5>{' '}
                  </tr>
                  <tr className='text row'>
                    {' '}
                    
                      {' '}
                    {' '}
                    <p>
                      {' '}
                      My name is Pavithra Chandrasekaran and I am currently
                      (2023-2024 school year) a senior at James E Taylor High
                      School. My aspiration is to become a neurosurgeon and open
                      a clinic in India with an emphasis on affordable, quality
                      care, and respectful communication. I am currently
                      involved with National Honor Society as the Educational
                      Committee head and South Asian Society as the president so
                      that I can cultivate the necessary service and culture
                      related knowledge to pursue my goal. Tanya and I started
                      ShareMyStory to help share patient’s experiences with
                      their diseases in order to benefit others who might be
                      coping with similar situations medically. I look forward
                      to seeing the impact that the stories on this page create
                      !{' '}
                    </p>{' '}
                   
                 </tr>
                  {/* <!-- Social Boxed -->  */}
                </table>{' '}
              </div>{' '}
            </div>
            <div className='news-block-two col-lg-6 col-md-4	col-sm-12'>
              {' '}
              <div
                className='inner-box wow fadeInLeft'
                data-wow-delay='0ms'
                data-wow-duration='1500ms'
              >
                {' '}
                <table>
                 <tr  className='image'>
                  {' '}
                  <img
                    style={{
                      alignContent:'center',
                      padding:'10px',
                       width: '50%',
                       height: '250px',
                     }}
                    src='../images/Tanya.jpg'
                  />
                  </tr>
                
               
                <tr className='lower-content row'>
                  {' '}
                  <h5> Tanya </h5>{' '}
                  </tr>
                  <tr className='text row'>
                    {' '}
                    <p>
                      {' '}
                      As a senior in high school at Katy ISD with aspirations to
                      become a pediatric physician, I wanted to positively
                      impact my community. Pavithra and I came up with the
                      concept for this website while considering what most
                      negatively affects those around us and what we can do to
                      combat that. Share My Story was created to be a way for
                      everybody to feel less alone and find solace in the
                      stories of others with the same disease. At my school, I’m
                      a member of Health Occupations Students of America and the
                      President of National Honor Society, enabling me to
                      positively change my community through volunteer work. I’m
                      incredibly excited to see the beneficial influence this
                      has on other people who are struggling with an illness!
                    </p>{' '}
                   
                  </tr>{' '}
                  {/* <!-- Social Boxed -->  */}
                </table>{' '}
              </div>{' '}
            </div>
          </div>{' '}
        </div>{' '}
      </section>{' '}
    </>
  )
}

export default Home
