import './App.css'
import './style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'bootstrap/dist/css/bootstrap.css'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Footer from './footer'
import Header from './Header'
import Home from './Home'
import Admin from './Admin'
import MainModule from './MainModule'
import Login from './Login'


function App() {

  
  return (
    <div className='App'  >
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/MainModule' element={<MainModule />} />
          <Route path='/admin' element={<Admin />} />
        </Routes>
        <Footer />
      </Router> 
     
    </div>
  )
}

export default App
