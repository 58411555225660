import { initializeApp } from 'firebase/app'
import { getFirestore, Timestamp } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

// Project Owner : sasibindu0611@gmail.com - please send request to get access
const firebaseConfig = {
  apiKey: 'AIzaSyCtNlRH_RWcmwI-t5MmMQ3tDZkBh27nfGo',
  authDomain: 'picvideo1973.firebaseapp.com',
  projectId: 'picvideo1973',
  storageBucket: 'picvideo1973.appspot.com',
  messagingSenderId: '737844069857',
  appId: '1:737844069857:web:b6f467fc9fde3f050abea0',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const myAuth = getAuth(app)
const storage = getStorage(app)

export { db, myAuth, storage, Timestamp }
